import styled from 'styled-components'

export const notfound_section = styled.section`
  width: 100%;
  background-color: ${(p) => p.theme.webMktPalette.background.gray};
  display: block;
`

export const notfound_container = styled.div`
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  padding: 80px 0px 128px 0px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
`

export const notfound_image = styled.img`
  padding: 10px;
  src: url(${(props) => props.src});
  display: flex;
  width: 100%;
  max-width: 540px;
`

export const notfound_content = styled.div`
  max-width: 590px;
  padding: 10px;

  h2 {
    font-size: 56px;
    font-weight: 800;
    letter-spacing: -1px;
    line-height: 64.4px;
  }

  p {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 24px;
    margin-top: 32px;
    color: #273139;
  }

  ul {
    margin-top: 24px;
    list-style-position: inside;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul li {
    padding-left: 30px;
    position: relative;
  }

  ul li:before {
    content: url(/steam-img/icons/icon_download_blue.svg);
    position: absolute;
    top: 0;
    left: 0;
  }

  ul li a {
    color: #0085ca;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0px;
    line-height: 24px;
    margin-top: 16px;
    cursor: pointer;
    font-family: Inter, sans-serif;
    transition: color 0.2s ease;
  }

  ul li a:hover {
    color: #002855;
    cursor: pointer;
    transition: color 0.2s ease;
  }
`
