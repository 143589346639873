import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layouts/layout'
import * as styles from '../styles/404'
import Container from '@material-ui/core/Container'

import notFoundImg from '../../static/steam-img/404-dsk.png'

const RootIndex = (props: any) => {
  const pageTitle: string = 'UiPath | 404'

  return (
    <Layout.Template lang="en-US">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Container data-cy="Page404">
        <styles.notfound_section>
          <styles.notfound_container>
            <styles.notfound_image src={notFoundImg} />
            <styles.notfound_content>
              <h2 className="Black">We’re stumped Sorry!</h2>
              <p className="section-p">
                We might have moved or deleted that page. Please double check
                the URL just to be sure.
              </p>
              <p className="section-p">
                If that doesn't help you can always return to the home page.
              </p>
              <ul>
                <li>
                  <a href="/">Go to home page</a>
                </li>
              </ul>
            </styles.notfound_content>
          </styles.notfound_container>
        </styles.notfound_section>
      </Container>
    </Layout.Template>
  )
}

export default RootIndex
